import { documentType } from '../../../utils/types';
import { enqueueSnackbar } from 'notistack';
import { handleErrors } from '../../../utils/functions';
import { showAlert } from '../../../redux/slices/appSlice';
import { useAppDispatch } from '../../../hooks/reduxHooks';
import { useCallback, useState } from 'react';
import useDialog from '../../../hooks/useDialog';
import useFetchs from './useFetchs';

const initialValues = { nombre: '', descripcion: '', idsTipoArchivo: [] };

function useDocumentTypes() {
    const dispatch = useAppDispatch();
    const [data, setData] = useState<documentType>(initialValues);
    const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();
    const { addDocumentType, deleteDocumentType, ...fetchProps } = useFetchs({ openDialog });
    const [filterModelValue, setFilterModelValue] = useState<string>('');

    const onInputChange = useCallback((e: any) => {
        setFilterModelValue(e?.target?.value || '');
    }, []);

    const setOpenDialog = () => {
        setData(initialValues);
        handleOpenDialog();
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value, name } = event?.target;
        setData({
            ...data,
            [name]: value
        });
    };

    const handleDelete = useCallback((e: any) => {
        const submit = async () => {
            const response: any = await deleteDocumentType(e?.id);
            if (!response?.error) {
                enqueueSnackbar(response?.data?.message || 'Registro eliminado correctamente.', {
                    variant: 'success'
                });
            } else {
                handleErrors(response?.error);
            }
        };

        dispatch(
            showAlert({
                title: '¿Eliminar tipo de documento?',
                message: `Si eliminas '${e?.row?.nombre}' ya no estará disponible`,
                keepMounted: true,
                cancelText: 'Cancelar',
                confirmText: 'Sí, eliminar',
                confirmAction: submit
            })
        );
        // eslint-disable-next-line
    }, []);

    const handleSubmit = async () => {
        const body: documentType = {
            nombre: data.nombre,
            idsTipoArchivo: data.idsTipoArchivo.map((item: any) => item.id.toString()),
            descripcion: data.descripcion
        };
        const response: any = await addDocumentType(body);
        if (!response?.error) {
            enqueueSnackbar(
                response?.data?.message || 'El tipo de documento se agregó con éxito.',
                {
                    variant: 'success'
                }
            );
            setData(initialValues);
            handleCloseDialog();
        } else {
            handleErrors(response?.error);
        }
    };

    return {
        data,
        openDialog,
        filterModelValue,
        handleSubmit,
        handleChange,
        handleDelete,
        setOpenDialog,
        handleOpenDialog,
        handleCloseDialog,
        onInputChange,
        ...fetchProps
    };
}

export default useDocumentTypes;
