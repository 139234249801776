import { Box, CircularProgress, Grid, IconButton, Tooltip } from '@mui/material';
import { CddDeleteIcon } from '../../../assets/icons/Icons';
import { ORIG_AUTORIZADOS_LABEL } from '../../../utils/states';
import AddIcon from '@mui/icons-material/Add';
import AutocompleteChips from '../../../components/AutocompleteChips/AutocompleteChips';
import Button from '../../../components/Button/Button';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Input from '../../../components/Input/Input';

const styles = {
    color: '#656565',
    fontSize: '17px',
    '& h3': {
        color: '#3D3D3D',
        margin: 0
    },
    '& p': {
        margin: '8px 0'
    }
};

function PermissionShowInfo({
    data,
    showInput,
    setShowInput,
    appIdToRemove,
    loadingDownloadApplications,
    fetchingDownloadApplications,
    removingDownloadApp,
    handleAddNewApps,
    handleRemoveDownloadApp,
    appOptions
}: any) {
    return (
        <Grid container spacing={2} sx={{ ...styles }}>
            <Grid item xs={12} sx={{}}>
                <p>
                    <b>Aplicación: </b> {data?.aplicacion?.nombre || 'S/D'}
                </p>
                <p>
                    <b>Documento: </b> {data?.tipoDocumento?.nombre || 'S/D'}
                </p>
            </Grid>
            <Grid item xs={12}>
                <h3>Acciones permitidas</h3>
                <p>
                    <b>Cargar: </b> {data?.cargar ? 'Si' : 'No'}
                </p>
                <p>
                    <b>Descargar: </b> {data?.descargar ? 'Si' : 'No'}
                </p>
                <p>
                    <b>Eliminar: </b> {data?.eliminar ? 'Si' : 'No'}
                </p>
            </Grid>
            {data?.cargar && (
                <Grid item xs={12}>
                    <h3>Requerimientos</h3>
                    <p>
                        <b>Fecha de vencimiento: </b> {data?.requiereVencimiento ? 'Si' : 'No'}
                    </p>
                    <p>
                        <b>CUIT/CUIL: </b> {data?.requiereCuilCuit ? 'Si' : 'No'}
                    </p>
                    <p>
                        <b>Referencia adicional: </b> {data?.requiereCustomId ? 'Si' : 'No'}
                    </p>
                </Grid>
            )}
            {data?.descargar && (
                <>
                    <Grid item xs={12} sx={{ display: 'block' }}>
                        <Box className="flex-space-between" mb={2}>
                            <h3>Orígenes autorizados</h3>
                            <Tooltip placement="top" title={ORIG_AUTORIZADOS_LABEL}>
                                <InfoOutlinedIcon sx={{ color: 'var(--alternative2)' }} />
                            </Tooltip>
                        </Box>
                        <Box
                            sx={{
                                '.MuiTextField-root': {
                                    margin: '4px 0 !important'
                                },
                                '.MuiInputBase-root': {
                                    height: '48px'
                                }
                            }}
                        >
                            {data?.aplicacionesDescarga?.map((item: any) => (
                                <Input
                                    key={item.id}
                                    fullWidth
                                    value={item?.nombre}
                                    readOnly
                                    endIcon={
                                        <IconButton onClick={() => handleRemoveDownloadApp(item)}>
                                            {removingDownloadApp && appIdToRemove === item?.id ? (
                                                <CircularProgress
                                                    size="0.8em"
                                                    sx={{ marginRight: '3px', color: '#971B2F' }}
                                                />
                                            ) : (
                                                <CddDeleteIcon />
                                            )}
                                        </IconButton>
                                    }
                                />
                            ))}
                        </Box>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        display="flex"
                        justifyContent="flex-end"
                        sx={{
                            '& .MuiButtonBase-root': {
                                border: 'none',
                                boxShadow: 'none',
                                background: '#D8E9FF',
                                color: 'var(--primary)',
                                height: '36px'
                            },
                            '& .MuiButtonBase-root.Mui-disabled': {
                                color: 'rgba(0, 0, 0, 0.26)',
                                boxShadow: 'none',
                                backgroundColor: 'rgba(0, 0, 0, 0.12)'
                            }
                        }}
                    >
                        <Button
                            disabled={
                                showInput ||
                                !appOptions?.showModalOptions?.length ||
                                loadingDownloadApplications ||
                                fetchingDownloadApplications
                            }
                            startIcon={<AddIcon />}
                            loading={loadingDownloadApplications || fetchingDownloadApplications}
                            onClick={() => setShowInput(true)}
                        >
                            Agregar aplicación
                        </Button>
                    </Grid>
                </>
            )}
            {showInput && (
                <Grid item xs={12} fontSize="14px" textAlign="center">
                    <AutocompleteChips
                        onChange={(value: any) => handleAddNewApps(value)}
                        disabled={loadingDownloadApplications || fetchingDownloadApplications}
                        options={appOptions?.showModalOptions}
                        loading={loadingDownloadApplications || fetchingDownloadApplications}
                        label="Seleccionar aplicaciones"
                    />
                </Grid>
            )}
        </Grid>
    );
}

export default PermissionShowInfo;
