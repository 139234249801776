import { Navigate, Route, Routes } from 'react-router-dom';
import Home from '../../../pages/Home/Home';

function RoutesManager() {
    return (
        <Routes>
            <Route path="/:pathId" element={<Home />} />
            <Route path="*" element={<>Página no encontrada</>} />
            <Route path="/" element={<Navigate to="/app/aplicaciones" replace />} />
        </Routes>
    );
}

export default RoutesManager;
