import { Navigate, Route, Routes } from 'react-router-dom';
import { selectCurrentUser } from '../../redux/slices/authSlice';
import { useAppSelector } from '../../hooks/reduxHooks';
import Alert from '../Alert/AlertDialog';
import Footer from '../Footer/Footer';
import Login from '../../pages/Login/Login';
import RoutesManager from './routes/RoutesManager';

type RoleProp = {
    user?: any;
};
const ProtectedRoute = ({ user }: RoleProp) =>
    user ? <RoutesManager /> : <Navigate to="/login" replace />;

function Layout() {
    const user = useAppSelector(selectCurrentUser);
    return (
        <div className="app-container">
            <main className="main-wrapper">
                <Routes>
                    <Route path="login" element={<Login />} />
                    <Route path="app/*" element={<ProtectedRoute user={user} />} />
                    <Route path="*" element={<p>Página no encontrada!</p>} />
                    <Route path="/" element={<Navigate to="app" replace />} />
                </Routes>
            </main>
            <Footer />
            <Alert />
        </div>
    );
}

export default Layout;
