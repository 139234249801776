import { Box } from '@mui/material';
import styles from '../styles.module.css';

function CustomNoResultsOverlay() {
    return (
        <Box className={styles['custom-result']}>
            <h2>No se encontraron resultados</h2>
            <p>Intenta de nuevo con parámetros de búsqueda diferentes.</p>
        </Box>
    );
}

export default CustomNoResultsOverlay;
