import {
    useGetApplicationsQuery,
    useGetDownloadApplicationsQuery
} from '../../../services/applicationsApi';
import { useGetDocumentTypesQuery } from '../../../services/documentTypesApi';
import {
    useAddDownloadAppsMutation,
    useAddPermissionMutation,
    useDeletePermissionMutation,
    useGetPermissionsQuery,
    useRemoveDownloadAppMutation
} from '../../../services/permissionsApi';

function useFetchs({
    openSaveModal,
    openShowModal,
    data
}: {
    openSaveModal: boolean;
    openShowModal: boolean;
    data?: any;
}) {
    const {
        data: items,
        isLoading: loadingPermissions,
        isFetching: fetchingPermissions,
        refetch: refetchPermissions
    } = useGetPermissionsQuery();

    const {
        data: applications,
        isLoading: loadingApplications,
        isFetching: fetchingApplications
    } = useGetApplicationsQuery(undefined, { skip: !openSaveModal });

    const {
        data: downloadApplications,
        isLoading: loadingDownloadApplications,
        isFetching: fetchingDownloadApplications
    } = useGetDownloadApplicationsQuery(
        {
            idTipoDocumento: data?.idTipoDocumento?.id || data?.tipoDocumento?.id || undefined,
            cargar: true
        },
        {
            skip:
                !data?.descargar ||
                (!openSaveModal && !openShowModal) ||
                (openSaveModal && !data?.idTipoDocumento),
            refetchOnMountOrArgChange: true
        }
    );

    const {
        data: documentTypes,
        isLoading: loadingDocumentTypes,
        isFetching: fetchingDocumentTypes
    } = useGetDocumentTypesQuery(undefined, { skip: !openSaveModal });

    const [addPermission, { isLoading: addingPermission }] = useAddPermissionMutation();
    const [deletePermission, { isLoading: deletingPermission }] = useDeletePermissionMutation();

    const [addDownloadApps, { isLoading: addingDownloadApps }] = useAddDownloadAppsMutation();
    const [removeDownloadApp, { isLoading: removingDownloadApp }] = useRemoveDownloadAppMutation();

    return {
        items,
        loadingPermissions,
        fetchingPermissions,
        addingPermission,
        deletingPermission,
        applications,
        documentTypes,
        addingDownloadApps,
        loadingDocumentTypes,
        fetchingDocumentTypes,
        loadingApplications,
        fetchingApplications,
        removingDownloadApp,
        downloadApplications,
        loadingDownloadApplications,
        fetchingDownloadApplications,
        refetchPermissions,
        addDownloadApps,
        removeDownloadApp,
        addPermission,
        deletePermission
    };
}

export default useFetchs;
