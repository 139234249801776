import { Box } from '@mui/material';
import { NO_ROWS } from '../../../utils/states';
import { useParams } from 'react-router-dom';
import styles from '../styles.module.css';

function CustomNoRowsOverlay() {
    const { pathId } = useParams();
    return (
        <Box className={styles['custom-result']}>
            <h2>Sin registros</h2>
            <p>
                No se encontraron {NO_ROWS[pathId!]?.path}. Haz click en el botón '
                {NO_ROWS[pathId!]?.buttonText}' para agregar registros.
            </p>
        </Box>
    );
}

export default CustomNoRowsOverlay;
