const API = 'core-cdd-api/';
const ADMIN = 'admin/';
const AMBIENT = process.env.REACT_APP_AMBIENT;
const APP_HOST = process.env.REACT_APP_HOST;

export const settings = {
    ambient: AMBIENT,
    url: {
        host: APP_HOST,
        api: API,
        admin: ADMIN
    }
};
