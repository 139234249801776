import {
    BaseQueryFn,
    FetchArgs,
    fetchBaseQuery,
    FetchBaseQueryError
} from '@reduxjs/toolkit/query';
import { logout, setAccessToken, setRefreshToken } from '../redux/slices/authSlice';
import { RootState } from '../redux/store';
import { settings } from '../settings';

const baseQuery = fetchBaseQuery({
    baseUrl: `${settings.url.host}${settings.url.api}${settings.url.admin}`,
    prepareHeaders: (headers, { getState }) => {
        const accessToken = (getState() as RootState).auth.accessToken;
        if (accessToken) {
            headers.set('authorization', `Bearer ${accessToken}`);
        }
        return headers;
    }
});

export const baseQueryWithReauth: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions) => {
    let result: any = await baseQuery(args, api, extraOptions);

    if (result.error && result.error.status === 401) {
        const { auth }: any = api.getState();
        const refreshResult: any = await baseQuery(
            {
                url: 'auth/refresh-session',
                method: 'POST',
                body: { username: auth?.user?.user, refreshToken: auth?.refreshToken }
            },
            api,
            extraOptions
        );
        if (refreshResult?.data?.success) {
            const accessToken = refreshResult.data?.data?.accessToken;
            const refreshToken = refreshResult.data?.data?.refreshToken;
            localStorage.setItem('accessToken', accessToken);
            localStorage.setItem('refreshToken', refreshToken);

            api.dispatch(setAccessToken(accessToken));
            api.dispatch(setRefreshToken(refreshToken));
            result = await baseQuery(args, api, extraOptions);
        } else {
            api.dispatch(logout());
        }
    }
    return result;
};
