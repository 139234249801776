import styled from '@emotion/styled';
import { Button as ButtonMUI, CircularProgress } from '@mui/material';

interface Props {
    children: string;
    type?: 'text' | 'outlined' | 'contained';
    startIcon?: any;
    endIcon?: any;
    onClick?: any;
    loading?: boolean;
    disabled?: boolean;
    style?: object;
    name?: string;
    strong?: boolean;
    fullWidth?: boolean;
}

const StyledButton = styled(ButtonMUI)`
    background-color: ${(props) => (props?.variant !== 'contained' ? 'inherit' : 'var(--primary)')};
    border-radius: 100px;
    text-transform: capitalize;
    text-wrap: nowrap;
    height: 46px;
    border: 1px solid var(--primary);
    color: ${(props) => (props?.variant === 'outlined' ? 'var(--primary)' : 'var(--white)')};

    &:hover {
        border: ${(props) =>
            props?.variant === 'text' ? '1px solid transparent' : '1px solid #3761ed'};
        background-color: ${(props) =>
            props?.variant === 'outlined' ? '#e2e1f4' : 'var(--white)'};
        color: var(--primary);
    }
    &:disabled {
        border-color: rgba(0, 0, 0, 0.12);
    }
`;

function Button({
    children,
    type,
    startIcon,
    endIcon,
    onClick,
    loading,
    disabled,
    style,
    fullWidth,
    name
}: Props) {
    return (
        <StyledButton
            name={name}
            style={style || {}}
            variant={type || 'contained'}
            startIcon={startIcon || null}
            endIcon={endIcon || null}
            type={onClick ? 'button' : 'submit'}
            onClick={onClick || null}
            disabled={disabled || false}
            fullWidth={fullWidth}
        >
            {children}
            {loading && (
                <CircularProgress style={{ margin: '0 10px' }} color="inherit" size="1.5em" />
            )}
        </StyledButton>
    );
}

export default Button;
