import DialogCdd from '../../../components/Dialog/DialogCdd';
import PermissionForm from './PermissionForm';

function PermissionSaveModal({
    openSaveModal,
    addingPermission,
    handleCloseSaveModal,
    handleSubmit,
    disabledSubmitPermission,
    ...props
}: any) {
    return (
        <DialogCdd
            title="Nuevo permiso"
            handleSubmit={handleSubmit}
            loading={addingPermission}
            handleCloseDialog={handleCloseSaveModal}
            openDialog={openSaveModal}
            disabled={disabledSubmitPermission}
        >
            <PermissionForm {...props} />
        </DialogCdd>
    );
}

export default PermissionSaveModal;
