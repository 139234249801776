import { enqueueSnackbar } from 'notistack';
import { handleErrors } from '../../../utils/functions';
import { showAlert } from '../../../redux/slices/appSlice';
import { useAppDispatch } from '../../../hooks/reduxHooks';
import { useCallback, useState } from 'react';
import useDialog from '../../../hooks/useDialog';
import useFetchs from './useFetchs';

function useFileTypes() {
    const dispatch = useAppDispatch();
    const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();
    const [data, setData] = useState<any>(null);
    const [filterModelValue, setFilterModelValue] = useState<string>('');
    const { addFileType, deleteFileType, ...fetchProps } = useFetchs();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value, name }: any = e?.target;
        if (!value) {
            let auxData = { ...data };
            delete auxData?.[name];
            setData(auxData);
        } else {
            if (name === 'fullName') {
                setData({
                    fullName: value,
                    fileType: '',
                    fileExtension: ''
                });
            } else {
                setData({
                    ...data,
                    [name]: value
                });
            }
        }
    };

    const setOpenDialog = () => {
        setData(null);
        handleOpenDialog();
    };

    const handleSubmit = async () => {
        const body = {
            nombre: data.fullName.nombre,
            tipo: data.fileType.nombre,
            extension: data.fileExtension.nombre
        };
        const response: any = await addFileType(body);
        if (!response?.error) {
            enqueueSnackbar(response?.data?.message || 'El tipo de archivo se agregó con éxito.', {
                variant: 'success'
            });
            setData(null);
            handleCloseDialog();
        } else {
            handleErrors(response?.error);
        }
    };

    const onInputChange = useCallback((e: any) => {
        setFilterModelValue(e?.target?.value || '');
    }, []);

    const handleDelete = useCallback((e: any) => {
        const submit = async () => {
            const response: any = await deleteFileType(e?.id);
            if (!response?.error) {
                enqueueSnackbar(response?.data?.message || 'Registro eliminado correctamente.', {
                    variant: 'success'
                });
            } else {
                handleErrors(response?.error);
            }
        };

        dispatch(
            showAlert({
                title: '¿Eliminar tipo de archivo?',
                message: `Si eliminas '${e?.row?.nombre}' ya no estará disponible`,
                keepMounted: true,
                cancelText: 'Cancelar',
                confirmText: 'Sí, eliminar',
                confirmAction: submit
            })
        );
        // eslint-disable-next-line
    }, []);

    return {
        data,
        filterModelValue,
        openDialog,
        setOpenDialog,
        handleDelete,
        handleChange,
        handleSubmit,
        handleOpenDialog,
        handleCloseDialog,
        onInputChange,
        ...fetchProps
    };
}

export default useFileTypes;
