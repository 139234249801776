import { Grid } from '@mui/material';
import Input from '../../../components/Input/Input';
import AutocompleteChips from '../../../components/AutocompleteChips/AutocompleteChips';

function DocumentosForm({
    fileTypes,
    data,
    loadingFileTypes,
    fetchingFileTypes,
    handleChange
}: any) {
    return (
        <Grid container spacing={2} pb={8}>
            <Grid item xs={12}>
                <Input
                    label="Nombre"
                    fullWidth
                    required
                    name="nombre"
                    value={data?.nombre || ''}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={12}>
                <AutocompleteChips
                    label="Seleccionar archivos"
                    value={data?.idsTipoArchivo || []}
                    fullWidth
                    required
                    onChange={(value: any) =>
                        handleChange({
                            target: {
                                name: 'idsTipoArchivo',
                                value: value
                            }
                        })
                    }
                    options={fileTypes || []}
                    templateLabel={(option: any) =>
                        `${option.nombre} (${option.extension.toUpperCase()})`
                    }
                    loading={loadingFileTypes || fetchingFileTypes}
                />
            </Grid>
            <Grid item xs={12}>
                <Input
                    label="Descripción"
                    fullWidth
                    required
                    name="descripcion"
                    value={data?.descripcion || ''}
                    onChange={handleChange}
                />
            </Grid>
        </Grid>
    );
}

export default DocumentosForm;
