import { DocumentHeaders } from '../../utils/headers';
import DataTable from '../../components/Table/DataTable';
import DialogCdd from '../../components/Dialog/DialogCdd';
import DocumentosForm from './components/DocumentosForm';
import SearchToolbar from '../../components/SearchToolbar/SearchToolbar';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import useDocumentTypes from './hooks/useDocumentTypes';

function Documentos() {
    const {
        items,
        openDialog,
        loadingItems,
        fetchingItems,
        filterModelValue,
        addingDocumentType,
        deletingDocumentType,
        onInputChange,
        handleSubmit,
        handleDelete,
        handleOpenDialog,
        handleCloseDialog,
        ...restProps
    } = useDocumentTypes();

    return (
        <>
            <SectionTitle>
                Tipos de documentos aceptados por la Web API. &nbsp;
                <em>Ejemplo: Contratos, recibos, facturas, etc.</em>
            </SectionTitle>

            <SearchToolbar
                inputLabel="Buscar documento"
                btnText="Nuevo documento"
                onButtonClick={handleOpenDialog}
                onInputChange={onInputChange}
            />

            <DataTable
                handleDeleteAction={handleDelete}
                withActions
                headers={DocumentHeaders}
                loading={loadingItems || fetchingItems || deletingDocumentType}
                filterModelValue={filterModelValue}
                options={items}
            />

            <DialogCdd
                title="Nuevo tipo de documento"
                openDialog={openDialog}
                handleSubmit={handleSubmit}
                loading={addingDocumentType}
                handleCloseDialog={handleCloseDialog}
            >
                <DocumentosForm {...restProps} />
            </DialogCdd>
        </>
    );
}

export default Documentos;
