import { Box } from '@mui/material';
import LoginForm from './components/LoginForm';
import styles from './login.styles.module.css';
import useLogin from './hooks/useLogin';

function Login() {
    const { ...props } = useLogin();
    return (
        <Box className={styles['login-container']}>
            <LoginForm {...props} />
        </Box>
    );
}

export default Login;
