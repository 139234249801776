import { Box, Drawer } from '@mui/material';
import Button from '../Button/Button';
import DrawerHeader from './components/DrawerHeader';

interface DrawerProps {
    open: boolean;
    onClose: () => void;
    title: string | React.ReactElement;
    children: React.ReactNode;
    icon?: React.ReactElement;
    loading?: boolean;
    onSubmit?: () => void;
    disabled?: boolean;
}

function CdDrawer({
    open,
    onClose,
    loading,
    children,
    onSubmit,
    disabled,
    ...drawerHeaderProp
}: DrawerProps) {
    return (
        <Drawer anchor="right" open={!!open} onClose={onClose}>
            <Box className="drawer-container">
                <DrawerHeader onClose={onClose} {...drawerHeaderProp} />
                <Box className="drawer-body">{children}</Box>
                <Box className="drawer-actions">
                    <Button onClick={onClose} type="outlined">
                        Cancelar
                    </Button>
                    <Button
                        disabled={disabled}
                        loading={loading}
                        onClick={() => {
                            if (onSubmit) onSubmit();
                        }}
                    >
                        Guardar
                    </Button>
                </Box>
            </Box>
        </Drawer>
    );
}

export default CdDrawer;
