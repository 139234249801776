import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    alert: {}
};

const appSlice = createSlice({
    name: 'alert',
    initialState,
    reducers: {
        showAlert: (state, { payload }) => {
            state.alert = payload;
        },
        hideAlert: (state) => {
            return (state = {
                ...state,
                alert: {}
            });
        }
    }
});

export default appSlice.reducer;

export const { showAlert, hideAlert } = appSlice.actions;
