import { FILE_EXTENSION, FILE_TYPES, FILETYPES_FULLNAME } from '../../../utils/states';
import { Grid } from '@mui/material';
import { useMemo } from 'react';
import AutocompleteComponent from '../../../components/Autocomplete/Autocomplete';

function FileTypeForm({ data, handleChange, items }: any) {
    const filteredOptions = useMemo(() => {
        return FILETYPES_FULLNAME.filter(
            (ft: { id: number; nombre: string; extension: string }) =>
                !items?.find((item: any) => item.extension === ft.extension)
        );
    }, [items]);

    return (
        <Grid container spacing={2} pb={8}>
            <Grid item xs={12}>
                <AutocompleteComponent
                    value={data?.fullName || ''}
                    name="fullName"
                    onChange={(value: { id: number; nombre: string }) =>
                        handleChange({
                            target: {
                                name: 'fullName',
                                value: value
                            }
                        })
                    }
                    label="Nombre"
                    options={filteredOptions}
                    required
                />
            </Grid>
            <Grid item xs={12}>
                <AutocompleteComponent
                    name="fileType"
                    value={data?.fileType || ''}
                    onChange={(value: { id: number; nombre: string }) =>
                        handleChange({
                            target: {
                                name: 'fileType',
                                value: value
                            }
                        })
                    }
                    label="Tipo"
                    required
                    disabled={!data?.fullName}
                    options={FILE_TYPES.filter((i: any) => i.id === data?.fullName?.id)}
                />
                <span style={{ color: '#476773', fontSize: '12px', paddingLeft: '3vpx' }}>
                    Tipo de medio:{' '}
                    <a
                        href="https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Common MME Types
                    </a>
                </span>
            </Grid>
            <Grid item xs={12}>
                <AutocompleteComponent
                    label="Extensión"
                    value={data?.fileExtension || ''}
                    name="fileExtension"
                    onChange={(value: { id: number; nombre: string }) =>
                        handleChange({
                            target: {
                                name: 'fileExtension',
                                value: value
                            }
                        })
                    }
                    required
                    disabled={!data?.fileType}
                    options={FILE_EXTENSION.filter((i: any) => i.id === data?.fullName?.id)}
                />
            </Grid>
        </Grid>
    );
}

export default FileTypeForm;
