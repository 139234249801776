import { hideAlert } from '../../redux/slices/appSlice';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { useMediaQuery, useTheme } from '@mui/material';
import Button from '../Button/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ReactDOM from 'react-dom';
import styles from './styles.module.css';
import warningIcon from '../../assets/warningIcon.png';

interface AlertProps {
    title?: string;
    message?: string;
    buttonText?: string;
    customButtons?: JSX.Element | null;
    confirmAction?: (value: any) => any;
    cancelAction?: (value: any) => any;
    itemData?: any;
    confirmText?: string;
    cancelText?: string;
    reactElement?: any;
    keepMounted?: boolean;
}
export function AlertDialog(props: AlertProps) {
    const {
        title,
        message,
        keepMounted = false,
        reactElement,
        confirmAction,
        cancelAction,
        itemData,
        customButtons,
        confirmText,
        cancelText
    } = props;

    const dispatch = useAppDispatch();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClose = () => dispatch(hideAlert());

    const handleAction = () => {
        handleClose();

        if (confirmAction) return confirmAction(itemData);
    };

    return (
        <Dialog
            className={styles.dialogstyle}
            open={!!props}
            onClose={
                keepMounted
                    ? () => {
                          return;
                      }
                    : handleClose
            }
            aria-labelledby="alert-cdd"
            data-testid="alert-component"
            fullWidth={true}
            disableEscapeKeyDown
            sx={{
                textAlign: 'center',
                borderRadius: '10px',
                '& .MuiPaper-root': {
                    borderRadius: '10px',
                    width: 'auto',
                    minWidth: isMobile ? 'auto' : '440px'
                },
                '& .MuiDialogActions-root': {
                    margin: '23px',
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'row',
                    gap: '10px',
                    '& button': {
                        textTransform: 'inherit',
                        fontSize: '14px'
                    }
                },
                '& .MuiDialogContent-root': {
                    padding: '0 24px',
                    overflowWrap: 'break-word'
                }
            }}
        >
            <div className={styles['alert-container']}>
                <div>
                    <img src={warningIcon} alt="warning-icon" />
                </div>

                <DialogTitle id="alert-dialog-title" className={styles.title}>
                    {title}
                </DialogTitle>
                {message && (
                    <DialogContent>
                        <DialogContentText>{message}</DialogContentText>
                    </DialogContent>
                )}
                {reactElement && <DialogContent>{reactElement}</DialogContent>}
                <DialogActions>
                    {customButtons ? (
                        customButtons
                    ) : (
                        <>
                            {cancelText && (
                                <Button type="outlined" onClick={cancelAction || handleClose}>
                                    {cancelText}
                                </Button>
                            )}
                            <Button
                                // className={styles.button}
                                onClick={handleAction}
                                type="contained"
                            >
                                {confirmText || 'Aceptar'}
                            </Button>
                        </>
                    )}
                </DialogActions>
            </div>
        </Dialog>
    );
}

export default function Alert() {
    const propsRedux = useAppSelector((state) => state.alert.alert);
    const alertElement = document.getElementById('alert')!;
    if (Object.keys(propsRedux).length < 1) return null;
    return ReactDOM.createPortal(<AlertDialog {...propsRedux} />, alertElement);
}
