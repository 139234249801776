import { DOWNLOAD_PUBLIC_URL } from '../../../utils/states';
import { FormControlLabel, FormLabel, Grid, Switch, Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Input from '../../../components/Input/Input';

function AppForm({ data, handleChange }: any) {
    return (
        <Grid container spacing={2} pb={8}>
            <Grid item xs={12}>
                <Input
                    value={data.nombre}
                    name="nombre"
                    onChange={handleChange}
                    label="Nombre de la aplicación"
                    fullWidth
                    required
                />
            </Grid>
            <Grid item xs={12}>
                <Input
                    value={data.cidi}
                    name="cidi"
                    onChange={(e: any) => {
                        if (e?.target?.value?.length < 11) {
                            handleChange({
                                target: {
                                    name: 'cidi',
                                    value: e.target.value
                                }
                            });
                        }
                    }}
                    label="Cod CIDI"
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <Input
                    value={data.vedi}
                    name="vedi"
                    onChange={(e: any) => {
                        if (e?.target?.value?.length < 11) {
                            handleChange({
                                target: {
                                    name: 'vedi',
                                    value: e?.target?.value
                                }
                            });
                        }
                    }}
                    label="Cod VEDI"
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <Input
                    value={data.descripción}
                    name="descripcion"
                    onChange={handleChange}
                    label="Descripción"
                    fullWidth
                />
            </Grid>
            <Grid
                item
                xs={12}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    '& svg': {
                        color: 'var(--alternative2)'
                    }
                }}
            >
                <FormControlLabel
                    control={
                        <Switch
                            checked={data.permiteUrlPublica}
                            name="permiteUrlPublica"
                            onChange={handleChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    }
                    label="Permite URLs públicas"
                />
                <Tooltip placement="top" title={DOWNLOAD_PUBLIC_URL}>
                    <InfoOutlinedIcon />
                </Tooltip>
            </Grid>
        </Grid>
    );
}

export default AppForm;
