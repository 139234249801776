const styles = {
    borderRadius: '36px',
    backgroundColor: '#F3FAF3',
    height: '27px',
    width: '104px',
    fontWeight: 600,
    display: 'inherit',
    alignItems: 'center',
    justifyContent: 'center'
};

function CustomTableElement(value: boolean): JSX.Element | null {
    return (
        <div style={{ ...styles, color: value ? '#4CAF50' : '#F44336' }}>{value ? 'Si' : 'No'}</div>
    );
}

export default CustomTableElement;
