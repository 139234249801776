import { useMediaQuery, useTheme } from '@mui/material';
import Header from '../../components/Header/Header';
import SectionManager from '../../components/SectionManager/SectionManager';

function Home() {
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <div className="bg-container">
            <>
                <Header />
                {!isTablet && <SectionManager />}
            </>
        </div>
    );
}

export default Home;
