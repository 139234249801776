import { Box } from '@mui/material';
import { CbaLogo } from '../../../assets/icons/Icons';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Button from '../../../components/Button/Button';
import Input from '../../../components/Input/Input';
import LoginErrorMessage from './LoginErrorMessage';
import styles from '../login.styles.module.css';

function LoginForm({
    user,
    loadingLogin,
    errors,
    showPassword,
    password,
    setPassword,
    setUser,
    handleShowPassword,
    handleChangeHelperText,
    getHelperText,
    handleSubmit
}: any) {
    return (
        <Box
            className={styles['form-wrapper']}
            component="form"
            onSubmit={(e: any) => {
                e.preventDefault();
                handleSubmit();
            }}
        >
            <Box className={styles['container']}>
                <CbaLogo height="99px" width="114px" />
                <p>
                    <span className={styles['upperLabel']}>CDD</span>
                    <br />
                    <span className={styles['lowerLabel']}>Centro de Documentación Digital</span>
                </p>

                <div className={styles['inputsContainer']}>
                    <Input
                        value={user || ''}
                        name="user"
                        onBlur={(e: any) =>
                            handleChangeHelperText({
                                target: { name: 'user', value: e?.target?.value }
                            })
                        }
                        required
                        onChange={(e: any) => setUser(e?.target?.value)}
                        label="Usuario"
                        helperText={getHelperText('user')}
                        fullWidth
                        errors={errors && !!errors?.['user']}
                    />
                    <Input
                        value={password || ''}
                        name="password"
                        onChange={(e: any) => setPassword(e?.target?.value)}
                        onBlur={(e: any) =>
                            handleChangeHelperText({
                                target: { name: 'password', value: e?.target?.value }
                            })
                        }
                        required
                        type={showPassword ? 'text' : 'password'}
                        label="Contraseña"
                        helperText={getHelperText('password')}
                        errors={errors && !!errors?.['password']}
                        fullWidth
                        endIcon={
                            showPassword ? (
                                <VisibilityOff onClick={handleShowPassword} />
                            ) : (
                                <Visibility onClick={handleShowPassword} />
                            )
                        }
                    />
                </div>

                {errors && <LoginErrorMessage errors={errors} />}

                <Button loading={loadingLogin} fullWidth>
                    {loadingLogin ? 'Ingresando...' : 'Ingresar'}
                </Button>
            </Box>
        </Box>
    );
}

export default LoginForm;
