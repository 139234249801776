import { Box, Input } from '@mui/material';
import Button from '../Button/Button';
import AddIcon from '@mui/icons-material/Add';

const inputStyles = {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: '24px',
    '& .MuiInputBase-root': {
        width: '404px',
        height: '55px'
    },
    '& .MuiInputBase-root:hover': {
        borderBottom: '1px solid #bababa'
    },
    '& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before': {
        borderBottom: '1px solid #bababa'
    },
    '& .MuiInputBase-root::after': {
        borderBottom: '1px solid var(--primary)'
    }
};

interface Props {
    inputLabel: string;
    btnText: string;
    onInputChange?: (value: any) => void;
    onButtonClick?: () => void;
}

function SearchToolbar({ inputLabel, btnText, onInputChange, onButtonClick }: Props) {
    return (
        <Box sx={{ ...inputStyles }}>
            <Input onChange={onInputChange} placeholder={inputLabel} />
            <Button onClick={onButtonClick} startIcon={<AddIcon />}>
                {btnText}
            </Button>
        </Box>
    );
}

export default SearchToolbar;
