import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CdDrawer from '../../../components/Drawer/CdDrawer';
import PermissionShowInfo from './PermissionShowInfo';

interface Props {
    openShowModal: any;
    editPermissionBtnValidation: boolean;
    handleCloseShowModal: () => void;
    handleEditPermission?: () => void;
    data: any;
    addingDownloadApps: boolean;
}

function PermissionViewModal({
    openShowModal,
    editPermissionBtnValidation,
    handleCloseShowModal,
    handleEditPermission,
    addingDownloadApps,
    ...restProps
}: Props) {
    return (
        <CdDrawer
            open={openShowModal}
            onClose={handleCloseShowModal}
            title="Detalle de permiso"
            icon={<ArrowBackIcon />}
            onSubmit={handleEditPermission}
            disabled={editPermissionBtnValidation}
            loading={addingDownloadApps}
        >
            <PermissionShowInfo {...restProps} handleEditPermission={handleEditPermission} />
        </CdDrawer>
    );
}

export default PermissionViewModal;
