import { ApplicationHeaders } from '../../utils/headers';
import AppForm from './components/AppForm';
import DataTable from '../../components/Table/DataTable';
import DialogCdd from '../../components/Dialog/DialogCdd';
import SearchToolbar from '../../components/SearchToolbar/SearchToolbar';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import useApplications from './hooks/useApplications';

function Aplicaciones() {
    const {
        data,
        items,
        openDialog,
        addingApp,
        deletingApp,
        loadingApplications,
        fetchingApplications,
        filterModelValue,
        setOpenDialog,
        handleCloseDialog,
        handleDelete,
        handleChange,
        handleSubmit,
        onInputChange
    } = useApplications();

    return (
        <>
            <SectionTitle>
                Listado de aplicaciones habilitadas para consumir la Web API.
            </SectionTitle>

            <SearchToolbar
                inputLabel="Buscar aplicación"
                btnText="Nueva Aplicación"
                onButtonClick={setOpenDialog}
                onInputChange={onInputChange}
            />

            <DataTable
                handleDeleteAction={handleDelete}
                headers={ApplicationHeaders}
                withActions
                loading={loadingApplications || fetchingApplications || deletingApp}
                options={items}
                filterModelValue={filterModelValue}
            />

            <DialogCdd
                title="Nueva Aplicación"
                openDialog={openDialog}
                handleSubmit={handleSubmit}
                handleCloseDialog={handleCloseDialog}
                loading={addingApp}
            >
                <AppForm data={data} handleChange={handleChange} />
            </DialogCdd>
        </>
    );
}

export default Aplicaciones;
