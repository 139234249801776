import { CbaLogo, MuniLogo } from '../../assets/icons/Icons';
import { Divider } from '@mui/material';
import styles from './styles.module.css';

function Footer() {
    return (
        <footer>
            <div className={styles['img-container']}>
                <CbaLogo height="58px" width="58px" />
                <Divider orientation="vertical" variant="middle" />
                <MuniLogo />
            </div>
            <div className={styles['bottom-line']}>
                <div />
                <div />
                <div />
            </div>
        </footer>
    );
}

export default Footer;
