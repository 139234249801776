import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
    Radio,
    RadioGroup,
    Tooltip
} from '@mui/material';
import { ORIG_AUTORIZADOS_LABEL } from '../../../utils/states';
import AutocompleteChips from '../../../components/AutocompleteChips/AutocompleteChips';
import AutocompleteComponent from '../../../components/Autocomplete/Autocomplete';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

function PermissionForm({
    data,
    applications,
    loadingApplications,
    loadingDocumentTypes,
    loadingDownloadApplications,
    fetchingDownloadApplications,
    filteredDocumentTypes,
    handleChange,
    appOptions
}: any) {
    return (
        <Grid container spacing={0}>
            <Grid item xs={12}>
                <AutocompleteComponent
                    label="Aplicación"
                    name="idAplicacion"
                    options={applications}
                    value={data?.idAplicacion || ''}
                    onChange={(value: any) =>
                        handleChange({
                            target: {
                                name: 'idAplicacion',
                                value: value
                            }
                        })
                    }
                    loading={loadingApplications}
                    required
                />
            </Grid>
            <Grid item xs={12}>
                <AutocompleteComponent
                    label="Tipo de documento"
                    name="idTipoDocumento"
                    options={filteredDocumentTypes}
                    value={data?.idTipoDocumento || ''}
                    onChange={(value: any) =>
                        handleChange({
                            target: {
                                name: 'idTipoDocumento',
                                value: value
                            }
                        })
                    }
                    disabled={!data?.idAplicacion}
                    loading={loadingDocumentTypes}
                    required
                />
            </Grid>
            <Grid item xs={12}>
                <Box sx={{ display: 'flex' }}>
                    <FormControl sx={{ m: '8px 0', width: '100%' }} variant="standard">
                        <FormLabel component="legend">
                            <b>Acciones permitidas</b>
                        </FormLabel>
                        <FormGroup sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={data?.cargar}
                                        onChange={handleChange}
                                        name="cargar"
                                    />
                                }
                                label="Cargar"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={data?.descargar}
                                        onChange={handleChange}
                                        name="descargar"
                                    />
                                }
                                label="Descargar"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={data?.eliminar}
                                        onChange={handleChange}
                                        name="eliminar"
                                    />
                                }
                                label="Eliminar"
                            />
                        </FormGroup>
                    </FormControl>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box
                    sx={{
                        display: 'flex'
                    }}
                >
                    <FormControl
                        sx={{ m: '8px 0', width: '100%' }}
                        component="fieldset"
                        variant="standard"
                    >
                        <FormLabel
                            component="legend"
                            sx={{
                                width: '100%',
                                display: 'inline-flex',
                                justifyContent: 'space-between',
                                '& svg': {
                                    color: 'var(--alternative2)'
                                }
                            }}
                        >
                            <b>Origenes autorizados</b>
                            <Tooltip placement="top" title={ORIG_AUTORIZADOS_LABEL}>
                                <InfoOutlinedIcon />
                            </Tooltip>
                        </FormLabel>
                        <AutocompleteChips
                            fullWidth
                            label="Seleccionar aplicaciones"
                            disabled={
                                !data?.descargar ||
                                loadingDownloadApplications ||
                                fetchingDownloadApplications
                            }
                            options={data?.idTipoDocumento ? appOptions?.saveModalOptions : []}
                            value={data?.idsAplicacionesDescargas}
                            loading={loadingDownloadApplications || fetchingDownloadApplications}
                            noOptionsText={
                                !data?.idTipoDocumento ? 'Seleccionar un tipo de documento*' : ''
                            }
                            onChange={(value: any) => {
                                handleChange({
                                    target: {
                                        name: 'idsAplicacionesDescargas',
                                        value: value
                                    }
                                });
                            }}
                        />
                    </FormControl>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <FormControl disabled={!data?.cargar}>
                    <FormLabel id="fecha-vencimiento">Fecha de vencimiento</FormLabel>
                    <RadioGroup
                        sx={{
                            display: 'flex',
                            justifyContent: 'start',
                            textAlign: 'left',
                            gap: '7rem'
                        }}
                        row
                        aria-labelledby="fecha-vencimiento"
                        name="requiereVencimiento"
                        onChange={handleChange}
                        value={data?.requiereVencimiento}
                    >
                        <FormControlLabel value="si" control={<Radio />} label="Si" />
                        <FormControlLabel value="no" control={<Radio />} label="No" />
                    </RadioGroup>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl disabled={!data?.cargar}>
                    <FormLabel id="cuilcuit">Requiere CUIT/CUIL</FormLabel>
                    <RadioGroup
                        sx={{
                            display: 'flex',
                            justifyContent: 'start',
                            textAlign: 'left',
                            gap: '7rem'
                        }}
                        row
                        aria-labelledby="cuilcuit"
                        name="requiereCuilCuit"
                        onChange={handleChange}
                        value={data?.requiereCuilCuit}
                    >
                        <FormControlLabel value="si" control={<Radio />} label="Si" />
                        <FormControlLabel value="no" control={<Radio />} label="No" />
                    </RadioGroup>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl disabled={!data?.cargar}>
                    <FormLabel id="ref-adicional">Requiere referencia adicional</FormLabel>
                    <RadioGroup
                        sx={{
                            display: 'flex',
                            justifyContent: 'start',
                            textAlign: 'left',
                            gap: '7rem'
                        }}
                        row
                        aria-labelledby="ref-adicional"
                        name="requiereCustomId"
                        onChange={handleChange}
                        value={data?.requiereCustomId}
                    >
                        <FormControlLabel value="si" control={<Radio />} label="Si" />
                        <FormControlLabel value="no" control={<Radio />} label="No" />
                    </RadioGroup>
                </FormControl>
            </Grid>
        </Grid>
    );
}

export default PermissionForm;
