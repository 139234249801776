import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from './baseQuery';
import { applicationType, downloadApplicationsType, newApplicationType } from '../utils/types';

export const applicationsApi = createApi({
    reducerPath: 'applications',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['applications', 'downloadApplications'],
    endpoints: (builder) => ({
        getApplications: builder.query<applicationType[], void>({
            query: () => ({
                url: 'aplicacion'
            }),
            providesTags: ['applications'],
            transformResponse: (response: { data: { aplicaciones: any } }) =>
                response?.data?.aplicaciones
        }),
        getDownloadApplications: builder.query<applicationType[], downloadApplicationsType>({
            query: (filters: downloadApplicationsType) => ({
                url: 'aplicacion',
                params: filters
            }),
            providesTags: ['downloadApplications'],
            transformResponse: (response: { data: { aplicaciones: any } }) =>
                response?.data?.aplicaciones
        }),
        addApplication: builder.mutation({
            query: (data: newApplicationType) => ({
                url: 'aplicacion',
                body: data,
                method: 'POST'
            }),
            invalidatesTags: (_, error) => (error ? [] : ['applications'])
        }),
        deleteApplication: builder.mutation({
            query: (appId: number) => ({
                url: `aplicacion/${appId}`,
                method: 'DELETE'
            }),
            invalidatesTags: (_, error) => (error ? [] : ['applications'])
        })
    })
});
export const {
    useGetDownloadApplicationsQuery,
    useGetApplicationsQuery,
    useAddApplicationMutation,
    useDeleteApplicationMutation,
    useLazyGetApplicationsQuery
} = applicationsApi;
