import { Box } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import styles from '../login.styles.module.css';

function LoginErrorMessage({ errors }: any) {
    const { message, statusCode } = errors;

    if (!statusCode) return null;

    let text =
        message ||
        'Usuario y/o contraseña no coinciden, intenta de nuevo o solicita a un administrador restablecer tu contraseña.';

    if (statusCode === 500) {
        text = 'Ha ocurrido un error. Por favor intenta más tarde.';
    }
    return (
        <Box className={styles['error-container']}>
            <div>
                <HighlightOffIcon sx={{ color: '#F44336' }} />
            </div>
            <p>{text}</p>
        </Box>
    );
}

export default LoginErrorMessage;
