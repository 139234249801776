import { Box, IconButton } from '@mui/material';
import React from 'react';

interface DrawerHeaderProps {
    onClose: () => void;
    title: string | React.ReactElement;
    icon?: React.ReactElement;
}

const drawerHeaderStyles = {
    color: 'var(--white)',
    background: 'var(--primary)',
    height: '41px',
    padding: '16px 32px',
    fontWeight: 700,
    alignItems: 'center',
    display: 'flex',
    '& svg': {
        marginRight: '5px'
    },
    '& button': {
        color: 'var(--white)',
        padding: '8px 8px 8px 0'
    }
};

function DrawerHeader({ onClose, title, icon }: DrawerHeaderProps) {
    return (
        <Box sx={{ ...drawerHeaderStyles }}>
            {icon && (
                <IconButton onClick={onClose} aria-label="fingerprint" color="primary">
                    {icon}
                </IconButton>
            )}
            <h3>{title}</h3>
        </Box>
    );
}

export default DrawerHeader;
