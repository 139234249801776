import {
    useAddFileTypeMutation,
    useDeleteFileTypeMutation,
    useGetFileTypesQuery
} from '../../../services/fileTypesApi';

function useFetchs() {
    const {
        data: items,
        isLoading: loadingFileTypes,
        isFetching: fetchingFileTypes
    } = useGetFileTypesQuery();

    const [addFileType, { isLoading: addingFileType }] = useAddFileTypeMutation();
    const [deleteFileType, { isLoading: deletingFileType }] = useDeleteFileTypeMutation();

    return {
        items,
        loadingFileTypes,
        fetchingFileTypes,
        addFileType,
        deleteFileType,
        deletingFileType,
        addingFileType
    };
}

export default useFetchs;
