export const MIME_TYPES: { [key: string]: string } = {
    DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    PDF: 'application/pdf',
    XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    PPT: 'application/vnd.ms-powerpoint',
    PPTX: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    DOC: 'application/msword',
    XLS: 'application/vnd.ms-excel',
    PNG: 'image/png',
    JPEG: 'image/jpeg'
};
