import React, { useEffect, useMemo } from 'react';
import { Box, Paper, Tab, Tabs } from '@mui/material';
import { HomeStorageIcon, ArticleShortcut, FolderManagedIcon } from '../../assets/icons/Icons';
import { useNavigate, useParams } from 'react-router-dom';
import Aplicaciones from '../../pages/Aplicaciones/Index';
import Archivos from '../../pages/Archivos/Index';
import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined';
import Documentos from '../../pages/Documentos/Index';
import Permisos from '../../pages/Permisos/Index';

const sectionManagerStyles = {
    margin: '32px 48px',
    overflow: 'auto',
    height: 'calc(100% - 124px)',
    '& .MuiPaper-root': {
        height: '-webkit-fill-available',
        width: '100%',
        minHeight: '600px',
        typography: 'body1',
        '& .MuiTabs-indicator': {
            backgroundColor: 'var(--primary)'
        }
    },
    '& .tabs-container': {
        height: 'inherit',
        padding: '24px 40px 24px 40px'
    },
    '& .section-wrapper': { height: 'calc(100% - 92px)' },
    '& .MuiTabs-root': {
        '& .MuiTab-root': {
            textTransform: 'capitalize',
            fontSize: '16px',
            minHeight: '48px'
        },
        '& .MuiTabs-flexContainer': {
            overflow: 'auto'
        }
    }
};

const PATHS: any = ['aplicaciones', 'tipos-documentos', 'tipos-archivos', 'permisos'];

function RenderComponent({ value }: { value: number }) {
    switch (value) {
        case 0:
            return <Aplicaciones />;
        case 1:
            return <Documentos />;
        case 2:
            return <Archivos />;
        case 3:
            return <Permisos />;
        default:
            return <></>;
    }
}

function SectionManager() {
    const { pathId } = useParams();
    const navigate = useNavigate();
    const pathIndex = useMemo(() => PATHS.findIndex((p: any) => p === pathId), [pathId]);
    const [value, setValue] = React.useState(pathIndex < 0 ? 0 : pathIndex);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        navigate(`/app/${PATHS[newValue]}`, { replace: true });
        setValue(newValue);
    };

    useEffect(() => {
        if (!PATHS?.find((p: any) => p === pathId)) {
            navigate('/app/aplicaciones', { replace: true });
        }
        // eslint-disable-next-line
    }, []);

    return (
        <Box sx={{ ...sectionManagerStyles }}>
            <Paper>
                <Box className="tabs-container">
                    <Tabs onChange={handleChange} value={value > 0 ? value : 0}>
                        <Tab
                            icon={<CreateNewFolderOutlinedIcon />}
                            iconPosition="start"
                            label="Aplicaciones"
                        />
                        <Tab
                            icon={<HomeStorageIcon color={value === 1 ? 'var(--primary)' : ''} />}
                            iconPosition="start"
                            label="Tipos de documentos"
                        />
                        <Tab
                            icon={<ArticleShortcut color={value === 2 ? 'var(--primary)' : ''} />}
                            iconPosition="start"
                            label="Tipos de archivos"
                        />
                        <Tab
                            icon={<FolderManagedIcon color={value === 3 ? 'var(--primary)' : ''} />}
                            iconPosition="start"
                            label="Permisos"
                        />
                    </Tabs>

                    <div className="section-wrapper">
                        <RenderComponent value={value} />
                    </div>
                </Box>
            </Paper>
        </Box>
    );
}

export default SectionManager;
