import { AppBar, Box, IconButton, Toolbar, Typography } from '@mui/material';
import { LogoHeaderCba } from '../../assets/icons/Icons';
import { logout } from '../../redux/slices/authSlice';
import { useAppDispatch } from '../../hooks/reduxHooks';
import LogoutIcon from '@mui/icons-material/Logout';

const appBarStyles = {
    '& .MuiAppBar-root': {
        background: 'var(--primary)',
        maxHeight: '60px',
        position: 'static'
    },
    '& .MuiToolbar-root': {
        display: 'flex',
        justifyContent: 'space-between',
        padding: { xs: '0 5px !important', sm: '0 24px !important', md: '0 48px !important' }
    },
    '& .MuiTypography-root': {
        fontSize: { xs: '14px', sm: '20px' },
        textWrap: 'balance',
        textAlign: 'center',
        fontWeight: 600
    }
};
function Header() {
    const dispatch = useAppDispatch();

    const handleLogOut = () => {
        dispatch(logout());
    };
    return (
        <Box sx={{ ...appBarStyles }}>
            <AppBar>
                <Toolbar>
                    <LogoHeaderCba />
                    <Typography variant="h6" component="div">
                        Centro de Documentación Digital
                    </Typography>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={handleLogOut}
                    >
                        <LogoutIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
        </Box>
    );
}

export default Header;
