import { useState } from 'react';

function useDialog() {
    const [openDialog, setOpenDialog] = useState<boolean>(false);

    const handleOpenDialog = () => setOpenDialog(true);
    const handleCloseDialog = () => setOpenDialog(false);

    return { openDialog, handleOpenDialog, handleCloseDialog };
}

export default useDialog;
