import { Chip, CircularProgress } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

export default function AutocompleteChips(props: any) {
    const {
        value,
        onChange,
        options = [],
        width = '100%',
        label,
        placeholder,
        size = 'medium',
        name = '',
        loading,
        required = false,
        templateLabel,
        limitTags,
        readOnly = false,
        disabled = false,
        fullWidth = false,
        noOptionsText = ''
    } = props;
    return (
        <Autocomplete
            multiple
            loading={loading}
            size={size}
            disableClearable={false}
            limitTags={limitTags || 3}
            id="multiple-limit-tags"
            value={value}
            onChange={(event, newValue) => {
                if (onChange) onChange([...newValue]);
                else return null;
            }}
            options={options}
            disabled={disabled}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            getOptionLabel={(option: any) => {
                return templateLabel ? templateLabel(option) : option?.nombre;
            }}
            renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                    <Chip
                        size={size}
                        label={templateLabel ? templateLabel(option) : option?.nombre}
                        {...getTagProps({ index })}
                        key={index}
                    />
                ))
            }
            noOptionsText={noOptionsText || 'Sin opciones'}
            readOnly={readOnly}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    name={name}
                    placeholder={placeholder}
                    required={required}
                    InputProps={{
                        ...params.InputProps,
                        required: required && value?.length === 0,
                        onKeyDown: (e) => {
                            if (e.key === 'Enter') {
                                e.stopPropagation();
                            }
                        },
                        endAdornment: (
                            <>
                                {loading ? (
                                    <CircularProgress color="inherit" size={20} />
                                ) : (
                                    params.InputProps.endAdornment
                                )}
                            </>
                        )
                    }}
                />
            )}
            sx={{ width: fullWidth ? '100%' : width || '' }}
        />
    );
}
