import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { applicationsApi } from '../services/applicationsApi';
import { authApi } from '../services/authApi';
import { documentTypesApi } from '../services/documentTypesApi';
import { fileTypesApi } from '../services/fileTypesApi';
import { permissionsApi } from '../services/permissionsApi';
import { persistReducer, persistStore } from 'redux-persist';
import appSlice from './slices/appSlice';
import authReducer from '../redux/slices/authSlice';
import storage from 'redux-persist/lib/storage';

const persistedAuthReducer = persistReducer({ key: 'root', storage }, authReducer);

export const store = configureStore({
    reducer: {
        [authApi.reducerPath]: authApi.reducer,
        auth: persistedAuthReducer,
        [applicationsApi.reducerPath]: applicationsApi.reducer,
        [documentTypesApi.reducerPath]: documentTypesApi.reducer,
        [fileTypesApi.reducerPath]: fileTypesApi.reducer,
        [permissionsApi.reducerPath]: permissionsApi.reducer,
        alert: appSlice
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        })
            .concat(authApi.middleware)
            .concat(applicationsApi.middleware)
            .concat(documentTypesApi.middleware)
            .concat(fileTypesApi.middleware)
            .concat(permissionsApi.middleware)
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
