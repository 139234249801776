import { EMAIL_REGEX, PASSWORD_REGEX } from '../../../utils/regex';
import { encode as base64_encode } from 'base-64';
import { setUserData } from '../../../redux/slices/authSlice';
import { useAppDispatch } from '../../../hooks/reduxHooks';
import { useLoginMutation } from '../../../services/authApi';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

function useLogin() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [errors, setErrors] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [user, setUser] = useState<any>('');
    const [password, setPassword] = useState<any>('');
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [helperText, setHelperText] = useState<any>(null);
    const [loginUser, { isLoading: loadingLogin }] = useLoginMutation();

    const handleChangeHelperText = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e?.target;
        if (!value) {
            let auxObject = { ...helperText };
            delete auxObject[name];
            setHelperText(auxObject);
        } else {
            setHelperText({
                ...helperText,
                [name]: value
            });
        }
    };

    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleSubmit = async () => {
        if (!validateUser()) return;
        setErrors(null);

        const data: { [key: string]: string } = {
            username: user,
            password: base64_encode(password)
        };

        try {
            await loginUser({ data }).unwrap();
            dispatch(
                setUserData({
                    user: user
                })
            );
            navigate('/app');
        } catch (e: any) {
            setErrors(e?.data);
        }
    };

    const validateUser = () => {
        if (user?.includes('@')) {
            if (!EMAIL_REGEX.test(user)) {
                setErrors({ user: 'El email debe ser válido' });
                return false;
            } else {
                setErrors({ ...errors, user: null });
            }
        }
        if (!PASSWORD_REGEX.test(password)) {
            setErrors({
                password:
                    'La contraseña debe contener al menos 8 caracteres, una letra mayúscula, una letra minúscula, un número y un caracter especial, sin espacios.'
            });
            return false;
        } else {
            setErrors(null);
        }
        return true;
    };

    const getHelperText = (value: string) => {
        if (value === 'user') {
            let text = 'Ingresá tu nombre de usuario';
            if (typeof errors?.[value] === 'string') return errors?.[value];
            else return !helperText?.[value] ? text : undefined;
        } else {
            let text = 'Ingresá tu contraseña';
            if (typeof errors?.[value] === 'string') return errors?.[value];
            return !helperText?.[value] ? 'Ingresá tu contraseña' : undefined;
        }
    };

    return {
        user,
        errors,
        loading,
        password,
        helperText,
        loadingLogin,
        showPassword,
        setUser,
        setPassword,
        validateUser,
        handleSubmit,
        getHelperText,
        handleShowPassword,
        handleChangeHelperText
    };
}

export default useLogin;
