import { enqueueSnackbar } from 'notistack';
import { REQUEST_RESPONSE } from './states';

export const handleErrors = (errors: any) => {
    if (Array.isArray(errors?.data?.message)) {
        return errors.data.message.forEach((arr: any) => {
            arr.forEach((msg: string) => {
                enqueueSnackbar(msg, {
                    variant: 'error'
                });
            });
        });
    } else {
        return enqueueSnackbar(errors?.data?.message || REQUEST_RESPONSE['error'], {
            variant: 'error'
        });
    }
};
