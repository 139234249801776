import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { logout, setAccessToken, setRefreshToken } from '../redux/slices/authSlice';
import { RootState } from '../redux/store';
import { settings } from '../settings';
import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query';

const baseQuery = fetchBaseQuery({
    baseUrl: `${settings.url.host}${settings.url.api}${settings.url.admin}`,
    prepareHeaders: (headers, { getState }) => {
        const accessToken = (getState() as RootState).auth.accessToken;
        if (!headers.has('Content-Type')) {
            headers.set('Content-Type', 'application/json');
        }
        if (accessToken) {
            headers.set('authorization', `Bearer ${accessToken}`);
        }
        return headers;
    }
});

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
    args,
    api,
    extraOptions
) => {
    let result: any = await baseQuery(args, api, extraOptions);

    if (result?.data?.data?.accessToken) {
        const accessToken = result.data.data.accessToken.jwtToken;
        const refreshToken = result.data.data.refreshToken.token;
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);

        api.dispatch(setAccessToken(accessToken));
        api.dispatch(setRefreshToken(refreshToken));
    }

    if (result.error && result.error.status === 401) {
        api.dispatch(logout());
    }
    return result;
};

export const authApi = createApi({
    reducerPath: 'authApi',
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        login: builder.mutation({
            query: ({ data }: any) => ({
                url: `auth/login`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: data
            })
        })
    })
});
export const { useLoginMutation } = authApi;
