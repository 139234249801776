import {
    useAddApplicationMutation,
    useDeleteApplicationMutation,
    useGetApplicationsQuery
} from '../../../services/applicationsApi';

function useFetchs() {
    const {
        data: items,
        isLoading: loadingApplications,
        isFetching: fetchingApplications
    } = useGetApplicationsQuery();
    const [addApplication, { isLoading: addingApp }] = useAddApplicationMutation();
    const [deleteApplication, { isLoading: deletingApp }] = useDeleteApplicationMutation();

    return {
        items,
        loadingApplications,
        fetchingApplications,
        deleteApplication,
        addApplication,
        addingApp,
        deletingApp
    };
}

export default useFetchs;
