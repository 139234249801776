import { SnackbarProvider } from 'notistack';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';

function SnackbarProviderCDD({ children }: any) {
    return (
        <SnackbarProvider
            preventDuplicate
            maxSnack={3}
            iconVariant={{
                success: <CheckCircleOutlineIcon />,
                error: <CancelOutlinedIcon />,
                warning: <WarningAmberRoundedIcon />
            }}
            autoHideDuration={6000}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
            }}
            style={{ pointerEvents: 'all' }}
        >
            {children}
        </SnackbarProvider>
    );
}

export default SnackbarProviderCDD;
