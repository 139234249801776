import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from './baseQuery';
import { permissionType } from '../utils/types';

export const permissionsApi = createApi({
    reducerPath: 'permissions',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['permissions'],
    endpoints: (builder) => ({
        getPermissions: builder.query<any, void>({
            query: () => ({
                url: 'permisos'
            }),
            providesTags: ['permissions'],
            transformResponse: (response: { data: any }) => response?.data
        }),
        addPermission: builder.mutation({
            query: (data: permissionType) => ({
                url: 'permisos',
                method: 'POST',
                body: data
            }),
            invalidatesTags: (_, error) => (error ? [] : ['permissions'])
        }),
        deletePermission: builder.mutation({
            query: (permissionId: number) => ({
                url: `permisos/${permissionId}`,
                method: 'DELETE'
            }),
            invalidatesTags: (_, error) => (error ? [] : ['permissions'])
        }),
        addDownloadApps: builder.mutation({
            query: ({
                appIds,
                permissionId
            }: {
                appIds: { idAplicaciones: string[] };
                permissionId: number;
            }) => ({
                url: `permisos/${permissionId}/aplicaciones-descarga`,
                method: 'POST',
                body: appIds
            }),
            invalidatesTags: (_, error) => (error ? [] : ['permissions'])
        }),
        removeDownloadApp: builder.mutation({
            query: ({ permissionId, appId }: { permissionId: number; appId: number }) => ({
                url: `permisos/${permissionId}/aplicaciones-descarga/${appId}`,
                method: 'DELETE'
            })
        })
    })
});
export const {
    useRemoveDownloadAppMutation,
    useAddDownloadAppsMutation,
    useGetPermissionsQuery,
    useAddPermissionMutation,
    useDeletePermissionMutation
} = permissionsApi;
